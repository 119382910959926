import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          You're on my website hooray! But it's not ready yet :( Come again later in 2022.
        </p>
      </header>
    </div>
  );
}

export default App;
